import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import { AppFacade } from '../../../+state';
import { LayoutFacade } from '../../+state';
import { AuthFacade } from '../../../auth/+state';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Organization } from '../../../models';
import { RouterFacade } from '../../../router/+state';
import { MatSidenav } from '@angular/material/sidenav';
import { Layout, Nav } from '../../../models';

@Component({
  selector: 'marrick-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, { static: false })
  sideNav: MatSidenav;

  // Create an Obserable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Filter out the current Organization from the list so the user
  // is able to only switch to other Organization.
  organizations$: Observable<Organization[]> = combineLatest([
    this.auth.organizations$,
    this.auth.organizationId$
  ]).pipe(map(([orgs, orgId]) => orgs.filter(org => org.id !== orgId)));

  handset$: Observable<BreakpointState>;
  tablet$: Observable<BreakpointState>;
  web$: Observable<BreakpointState>;
  portrait$: Observable<BreakpointState>;
  landscape$: Observable<BreakpointState>;

  year: string = new Date().getFullYear().toString();

  constructor(
    public app: AppFacade,
    public layout: LayoutFacade,
    private router: RouterFacade,
    public auth: AuthFacade,
    private breakpointObserver: BreakpointObserver
  ) {}

  appLayout: Layout;

  ngOnInit() {
    // Track breckpoints for Handset, Table, Web, Portrait & Landscape
    this.handset$ = this.breakpointObserver.observe([Breakpoints.Handset]);
    this.tablet$ = this.breakpointObserver.observe([Breakpoints.Tablet]);
    this.web$ = this.breakpointObserver.observe([Breakpoints.Web]);
    this.portrait$ = this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait
    ]);
    this.landscape$ = this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]);

    // Combine all the above observables, into one observable that
    // updates the store.
    combineLatest([
      this.handset$,
      this.tablet$,
      this.web$,
      this.portrait$,
      this.landscape$
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([handset, tablet, web, portrait, landscape]) => {
          this.appLayout = {
            handset: handset.matches,
            tablet: tablet.matches,
            web: web.matches,
            portrait: portrait.matches,
            landscape: landscape.matches
          };
          this.layout.setLayout(this.appLayout);
        })
      )
      .subscribe();
  }

  login() {
    this.auth.login();
  }

  logout() {
    this.auth.logout();
  }

  // The Header component sends in the path is based
  // on the user's roles.
  organization(path: string) {
    this.router.go({ path: [path] });
  }

  // The Header component sends in the Organization ID
  switchOrganization(orgId: string) {
    this.auth.switchOrganizationId(orgId);
  }

  // If the user clicks a nav item in the side-nav
  // this event fires.
  // Close the Drawer if in handset mode.
  navigate(item: Nav) {
    if (this.appLayout.handset) {
      this.sideNav.close();
    }
  }

  logoLoaded(isLoading: boolean) {
    this.layout.setLogoLoading(isLoading);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
