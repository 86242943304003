import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromActions from './preferences.actions';
import * as fromSelectors from './preferences.selector';
import { Preferences } from '../../models';

@Injectable({ providedIn: 'root' })
export class PreferencesFacade {
  preferences$: Observable<Preferences> = this.store.pipe(
    select(fromSelectors.selectPreferencesState)
  );

  themeName$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectThemeName)
  );

  itemsPerPage$(props: Object): Observable<number> {
    return this.store.pipe(select(fromSelectors.selectItemsPerPage, props));
  }

  constructor(private store: Store<Preferences>) {}

  loadPreferences() {
    this.store.dispatch(new fromActions.LoadPreferencesAction());
  }

  saveItemsPerPage(itemsPerPage: { [key: string]: number }) {
    this.store.dispatch(new fromActions.SaveItemsPerPageAction(itemsPerPage));
  }

  saveThemeName(themeName: string) {
    this.store.dispatch(new fromActions.SaveThemeNameAction(themeName));
  }
}
