import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Preferences } from '../../models';

export const selectPreferencesState = createFeatureSelector<Preferences>(
  'preferences'
);

export const selectItemsPerPage = createSelector(
  selectPreferencesState,
  (state: Preferences, props: any) => state.itemsPerPage[props.name]
);

export const selectThemeName = createSelector(
  selectPreferencesState,
  state => state.themeName
);
