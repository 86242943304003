import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { FirestoreService } from '../auth/services';
import { SnackbarService } from '../core/services';
import { AuthFacade } from '../auth/+state/';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  filter,
  catchError,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import * as fromActions from './app.actions';
import { ActionTypes, SetLoggedOutAction } from '../auth/+state/auth.actions';
import { ErrorService } from '../core/services';
import { LoadDocTypesSuccessAction } from '../+state/app.actions';
import {
  USState,
  Prefix,
  DocType,
  PortalConfiguration,
  PhoneType,
  Preferences
} from '../models';

@Injectable()
export class AppEffects {
  @Effect()
  loadStates$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.LoadStatesAction>(fromActions.ActionTypes.LoadStates)
    ),
    this.auth.authenticated$
  ]).pipe(
    filter(([_, auth]) => !!auth),
    switchMap(() =>
      this.firestoreUtils.doc$<USState>(`codes/states`).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        map(states => new fromActions.LoadStatesSuccessAction(states.data)),
        catchError(err => of(new fromActions.LoadStatesErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  loadStatesError$ = this.actions$.pipe(
    ofType<fromActions.LoadStatesErrorAction>(
      fromActions.ActionTypes.LoadStatesError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred retrieving States.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect()
  loadPrefixes$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.LoadPrefixesAction>(
        fromActions.ActionTypes.LoadPrefixes
      )
    ),
    this.auth.authenticated$
  ]).pipe(
    filter(([action, auth]) => !!auth),
    switchMap(() =>
      this.firestoreUtils.doc$<Prefix>(`codes/prefixes`).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        map(
          prefixes => new fromActions.LoadPrefixesSuccessAction(prefixes.data)
        ),
        catchError(err => of(new fromActions.LoadPrefixesErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  loadPrefixesError$ = this.actions$.pipe(
    ofType<fromActions.LoadPrefixesErrorAction>(
      fromActions.ActionTypes.LoadPrefixesError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred retrieving Prefixes.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect()
  loadPhoneTypes$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.LoadPhoneTypesAction>(
        fromActions.ActionTypes.LoadPhoneTypes
      )
    ),
    this.auth.authenticated$
  ]).pipe(
    filter(([_, auth]) => !!auth),
    switchMap(() =>
      this.firestoreUtils.doc$<PhoneType>(`codes/phone-types`).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        map(doc => new fromActions.LoadPhoneTypesSuccessAction(doc.data)),
        catchError(err => of(new fromActions.LoadPhoneTypesErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  loadPhoneTypesError$ = this.actions$.pipe(
    ofType<fromActions.LoadPhoneTypesErrorAction>(
      fromActions.ActionTypes.LoadPhoneTypesError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred retrieving Phone Types.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect()
  loadPortalConfig$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.GetPortalConfigAction>(
        fromActions.ActionTypes.GetPortalConfig
      )
    ),
    this.auth.authenticated$,
    this.auth.organization$
  ]).pipe(
    filter(([action, authenticated, org]) => !!(authenticated && org)),
    switchMap(([action, authenticated, organization]) => {
      // If the user is readonly get the config from it's Organization Parent.
      let orgId = organization.id;
      if (
        organization.parents &&
        organization.parents.length > 0 &&
        organization.parents[0].length > 0
      ) {
        // Hard code the first Parent until portal configuration can be
        // properly implemented.
        orgId = organization.parents[0];
      }
      return this.firestoreUtils
        .doc$<PortalConfiguration>(`configuration/${orgId}`)
        .pipe(
          takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
          map(config => new fromActions.GetPortalConfigSuccessAction(config)),
          catchError(err => of(new fromActions.GetPortalConfigErrorAction(err)))
        );
    })
  );

  @Effect()
  loadPortalConfigSuccess$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.GetPortalConfigSuccessAction>(
        fromActions.ActionTypes.GetPortalConfigSuccess
      )
    ),
    this.auth.authenticated$
  ]).pipe(
    filter(([action, authenticated]) => !!authenticated),
    switchMap(([action, authenticated]) => [
      new LoadDocTypesSuccessAction(action.payload.docTypes),
      new fromActions.LoadTransactionTypesSuccessAction(
        action.payload.transactionTypes
      )
    ]),
    catchError(err => of(new fromActions.GetPortalConfigErrorAction(err)))
  );

  @Effect({ dispatch: false })
  loadPortalConfigError$ = this.actions$.pipe(
    ofType<fromActions.GetPortalConfigErrorAction>(
      fromActions.ActionTypes.GetPortalConfigError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred retrieving Portal configuration.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect({ dispatch: false })
  showSnackBar$ = this.actions$.pipe(
    ofType<fromActions.ShowSnackbarAction>(
      fromActions.ActionTypes.ShowSnackBar
    ),
    map(action => this.snackbar.showSnackBar(action.payload))
  );

  @Effect({ dispatch: false })
  showSnackBarError$ = this.actions$.pipe(
    ofType<fromActions.ShowSnackbarErrorAction>(
      fromActions.ActionTypes.ShowSnackBarError
    ),
    map(action => this.snackbar.showSnackBarError(action.payload))
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType<SetLoggedOutAction>(ActionTypes.SetLoggedOut),
    map(action => new fromActions.ClearStateAction())
  );

  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private error: ErrorService,
    private afAuth: AngularFireAuth,
    private firestoreUtils: FirestoreService,
    private snackbar: SnackbarService
  ) {}
}
