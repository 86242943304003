import * as actions from './preferences.actions';
import { Preferences } from '../../models';

export const initialState: Preferences = {
  itemsPerPage: {
    'credentials-list': 5,
    'documents-list': 5,
    'organization-patients-list': 5,
    'organizations-list': 5,
    'patients-list': 5,
    'users-list': 5,
    'transactions-list': 5,
    'bill-list': 5,
    'report-users-list': 5,
  },
  themeName: 'purple_lightpurple_theme',
};

export function reducer(
  state: Preferences = initialState,
  action: actions.PreferencesActionsUnion
) {
  switch (action.type) {
    case actions.ActionTypes.LoadPreferencesSuccess: {
      if (action.payload) {
        return {
          ...state,
          itemsPerPage: action.payload.itemsPerPage,
          themeName: action.payload.themeName,
        };
      } else {
        return { ...state };
      }
    }

    case actions.ActionTypes.LoadPreferencesError: {
      return { ...state, error: action.payload };
    }

    case actions.ActionTypes.ClearState: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
