import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthGuardUserService,
  AuthGuardAdminService,
  AuthGuardMarrickService,
  AuthGuardReportsService
} from './auth/services';
import {
  LoginComponent,
  RequestPasswordResetComponent,
  AccountErrorComponent,
  PasswordResetComponent
} from './auth/components';
import { NotFoundComponent } from './layout/components';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/patients',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password-reset-request',
    component: RequestPasswordResetComponent
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent
  },
  {
    path: 'dashboard',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'reports',
    canActivateChild: [AuthGuardReportsService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'organizations',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./organizations/organizations.module').then(
        m => m.OrganizationsModule
      )
  },
  {
    path: 'patients',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./patients/patients.module').then(m => m.PatientsModule)
  },
  {
    path: 'documents',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'credentials',
    canActivateChild: [AuthGuardAdminService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./credentials/credentials.module').then(m => m.CredentialsModule)
  },
  {
    path: 'users',
    canActivateChild: [AuthGuardMarrickService],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'transactions',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./transactions/transactions.module').then(
        m => m.TransactionsModule
      )
  },
  {
    path: 'profile',
    canActivateChild: [AuthGuardUserService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    canActivateChild: [AuthGuardMarrickService],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'account-error',
    component: AccountErrorComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
