import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { LayoutFacade } from '../../../+state';
import { PreferencesFacade } from '../../../../preferences/+state';
import { Theme } from '../../../../models';

@Component({
  selector: 'marrick-theme-picker',
  templateUrl: './theme-picker.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ThemePickerContainerComponent {
  constructor(
    public layout: LayoutFacade,
    private preferences: PreferencesFacade
  ) {
    this.layout.loadThemes();
  }

  setTheme(theme: Theme) {
    this.layout.setTheme(theme);
    this.preferences.saveThemeName(theme.name);
  }
}
