import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { SnackBarInfo } from 'src/app/models';

@Component({
  selector: 'marrick-snackbar-error',
  template: `
    <div class="container">
      <div class="message">
        <span class="snack-bar-success">{{ data.message }}</span>
        <br />
        <span class="snack-bar-success"
          >Contact Administrator. Code: {{ data.errorCode }}</span
        >
      </div>
      <button
        mat-button
        color="warn"
        ngxClipboard
        [cbContent]="
          data.message + ' Contact Administrator. Code: ' + data.errorCode
        "
        (click)="snackBarRef.dismiss()"
      >
        Copy
      </button>
      <button mat-icon-button (click)="snackBarRef.dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        flex-direction: row;
      }
      .message {
        padding-right: 15px;
      }
      .snack-bar-success {
        font-size: 0.9em;
      }
    `
  ]
})
export class SnackBarErrorComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarInfo
  ) {}
}
