import { NgModule, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreModule } from '../core/core.module';
import { StoreModule, Store } from '@ngrx/store';
import { LayoutFacade } from './+state';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/layout.reducer';
import { LayoutEffects } from './+state/layout.effects';

import {
  LayoutComponent,
  LoadingComponent,
  NavTreeComponent,
  HeaderComponent,
  FooterComponent,
  ThemePickerContainerComponent,
  ThemePickerComponent,
  NotFoundComponent,
  FeedbackComponent
} from './components';
@NgModule({
  declarations: [
    LayoutComponent,
    LoadingComponent,
    NavTreeComponent,
    HeaderComponent,
    FooterComponent,
    ThemePickerContainerComponent,
    ThemePickerComponent,
    NotFoundComponent,
    FeedbackComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    ReactiveFormsModule,
    StoreModule.forFeature('layout', reducer),
    EffectsModule.forFeature([LayoutEffects])
  ],
  entryComponents: [FeedbackComponent],
  exports: [LayoutComponent]
})
export class LayoutModule implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private layout: LayoutFacade, private store: Store<any>) {
    combineLatest([this.layout.themes$, this.layout.themeName$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([themes, themeName]) => {
        if (themes.length > 0) {
          const theme = themes.find(t => t.name === themeName);
          if (theme) {
            this.layout.setTheme(theme);
            this.layout.setThemeName(theme.name);
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
