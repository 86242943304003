import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromReducer from './auth.reducer';
import * as fromActions from './auth.actions';
import * as fromSelectors from './auth.selectors';
import {
  LoginCredential,
  ResetPassword,
  ResetPasswordRequest,
  Organization,
  UserCredential,
  ChangePasswordCredential
} from '../../models';
import { AppFacade } from 'src/app/+state';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  authenticated$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectAuthenticated)
  );

  roles$: Observable<Array<string>> = this.store.pipe(
    select(fromSelectors.selectRoles)
  );

  organizationId$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationId)
  );

  organization$: Observable<Organization> = this.store.pipe(
    select(fromSelectors.selectOrganization)
  );

  organizations$: Observable<Array<Organization>> = this.store.pipe(
    select(fromSelectors.selectOrganizations)
  );

  organizationName$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationName)
  );

  organizationLogoUrl$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationLogoUrl)
  );

  organizationUrl$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectOrganizationUrl)
  );

  uid$: Observable<string> = this.store.pipe(select(fromSelectors.selectUid));

  firebaseToken$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectFirebaseToken)
  );

  photoUrl$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectPhotoUrl)
  );

  auth$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectAuthenticated)
  );

  apiHeaders$: Observable<HttpHeaders> = combineLatest([
    this.firebaseToken$,
    this.app.entityType$
  ]).pipe(
    map(([firebaseToken, entityType]) => {
      return new HttpHeaders()
        .set('Authorization', firebaseToken)
        .set('Entity-Type', entityType);
    })
  );

  constructor(
    private store: Store<fromReducer.State>,
    private app: AppFacade
  ) {}

  login() {
    this.store.dispatch(new fromActions.LoginAction());
  }

  logout() {
    this.store.dispatch(new fromActions.LogoutAction());
  }

  loginWithCredentials(credentials: LoginCredential) {
    this.store.dispatch(
      new fromActions.LoginWithCredentialsAction(credentials)
    );
  }

  loginError(err) {
    this.store.dispatch(new fromActions.LoginErrorAction(err));
  }

  setLoggedOut() {
    this.store.dispatch(new fromActions.SetLoggedOutAction());
  }

  resetPassword(body: ResetPassword) {
    this.store.dispatch(new fromActions.ResetPasswordAction(body));
  }

  resetPasswordRequest(body: ResetPasswordRequest) {
    this.store.dispatch(new fromActions.ResetPasswordRequestAction(body));
  }

  adminResetPassword(userCredential: UserCredential) {
    this.store.dispatch(
      new fromActions.AdminResetPasswordAction(userCredential)
    );
  }

  changePassword(credentials: ChangePasswordCredential) {
    this.store.dispatch(new fromActions.ChangePasswordAction(credentials));
  }

  switchOrganizationId(organizationId: string) {
    this.store.dispatch(
      new fromActions.SwitchOrganizationAction(organizationId)
    );
  }

  error(err: any) {
    this.store.dispatch(new fromActions.ErrorAction(err));
  }
}
