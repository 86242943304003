import { Action } from '@ngrx/store';
import {
  USState,
  Prefix,
  DocType,
  PortalConfiguration,
  SnackBarInfo,
  PhoneType,
  TransactionType
} from '../models';

export enum ActionTypes {
  LoadStates = '[App] Load States',
  LoadStatesSuccess = '[App] Load States Success',
  LoadStatesError = '[App] Load States Error',
  LoadPrefixes = '[App] Load Prefixes',
  LoadPrefixesSuccess = '[App] Load Prefixes Success',
  LoadPrefixesError = '[App] Load Prefixes Error',
  LoadDocTypesSuccess = '[App] Load Document Types Success',
  LoadTransactionTypesSuccess = '[App] Load Transaction Types Success',
  LoadPhoneTypes = '[App] Load Phone Types',
  LoadPhoneTypesSuccess = '[App] Load Phone Types Success',
  LoadPhoneTypesError = '[App] Load Phone Types Error',

  LoadDisplayColumns = '[App] Load Display Columns',
  LoadDisplayColumnsSuccess = '[App] Load Display Columns Success',
  LoadDisplayColumnsError = '[App] Load Display Columns Error',

  GetPortalConfig = '[App] Get Portal Config',
  GetPortalConfigSuccess = '[App] Get Portal Config Success',
  GetPortalConfigError = '[App] Get Portal Config Error',

  ShowSnackBar = '[App] Show Snackbar',
  ShowSnackBarError = '[App] Show Snackbar Error',

  SetRedirectUrl = '[App] Redirect URL',
  SetApiRoot = '[App] Set API Root',
  SetEnvironment = '[App] Set Environment',
  SetEntityType = '[App] Set Entity Type',
  SetResetPasswordHTML = '[App] Set Reset Password HTML',
  SetCompiledPDFsRoot = '[App] Set Compiled PDFs Root',

  ClearState = '[App] Clear State'
}

export class LoadStatesAction implements Action {
  readonly type = ActionTypes.LoadStates;
}
export class LoadStatesSuccessAction implements Action {
  readonly type = ActionTypes.LoadStatesSuccess;
  constructor(public payload: USState[]) {}
}

export class LoadStatesErrorAction implements Action {
  readonly type = ActionTypes.LoadStatesError;
  constructor(public payload: string) {}
}

export class LoadPrefixesAction implements Action {
  readonly type = ActionTypes.LoadPrefixes;
}
export class LoadPrefixesSuccessAction implements Action {
  readonly type = ActionTypes.LoadPrefixesSuccess;
  constructor(public payload: Prefix[]) {}
}

export class LoadPrefixesErrorAction implements Action {
  readonly type = ActionTypes.LoadPrefixesError;
  constructor(public payload: string) {}
}

export class LoadDocTypesSuccessAction implements Action {
  readonly type = ActionTypes.LoadDocTypesSuccess;
  constructor(public payload: DocType[]) {}
}
export class LoadPhoneTypesAction implements Action {
  readonly type = ActionTypes.LoadPhoneTypes;
}
export class LoadPhoneTypesSuccessAction implements Action {
  readonly type = ActionTypes.LoadPhoneTypesSuccess;
  constructor(public payload: Array<PhoneType>) {}
}

export class LoadPhoneTypesErrorAction implements Action {
  readonly type = ActionTypes.LoadPhoneTypesError;
  constructor(public payload: string) {}
}

export class LoadDisplayColumnsAction implements Action {
  readonly type = ActionTypes.LoadDisplayColumns;
}
export class LoadDisplayColumnsSuccessAction implements Action {
  readonly type = ActionTypes.LoadDisplayColumnsSuccess;
  constructor(public payload: { [key: string]: Array<string> }) {}
}

export class LoadDisplayColumnsErrorAction implements Action {
  readonly type = ActionTypes.LoadDisplayColumnsError;
  constructor(public payload: any) {}
}

export class LoadTransactionTypesSuccessAction implements Action {
  readonly type = ActionTypes.LoadTransactionTypesSuccess;
  constructor(public payload: Array<TransactionType>) {}
}

export class GetPortalConfigAction implements Action {
  readonly type = ActionTypes.GetPortalConfig;
}
export class GetPortalConfigSuccessAction implements Action {
  readonly type = ActionTypes.GetPortalConfigSuccess;
  constructor(public payload: PortalConfiguration) {}
}
export class GetPortalConfigErrorAction implements Action {
  readonly type = ActionTypes.GetPortalConfigError;
  constructor(public payload?: any) {}
}
export class ShowSnackbarAction implements Action {
  readonly type = ActionTypes.ShowSnackBar;
  constructor(public payload: SnackBarInfo) {}
}
export class ShowSnackbarErrorAction implements Action {
  readonly type = ActionTypes.ShowSnackBarError;
  constructor(public payload: SnackBarInfo) {}
}

export class SetRedirectUrlAction implements Action {
  readonly type = ActionTypes.SetRedirectUrl;
  constructor(public payload: string) {}
}
export class SetApiRootAction implements Action {
  readonly type = ActionTypes.SetApiRoot;
  constructor(public payload: string) {}
}
export class SetEnvironmentAction implements Action {
  readonly type = ActionTypes.SetEnvironment;
  constructor(public payload: string) {}
}
export class SetEntityTypeAction implements Action {
  readonly type = ActionTypes.SetEntityType;
  constructor(public payload: string) {}
}
export class SetResetPasswordHTMLAction implements Action {
  readonly type = ActionTypes.SetResetPasswordHTML;
  constructor(public payload: string) {}
}
export class SetCompiledPDFsRootAction implements Action {
  readonly type = ActionTypes.SetCompiledPDFsRoot;
  constructor(public payload: string) {}
}
export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}

export type ActionsUnion =
  | LoadStatesAction
  | LoadStatesSuccessAction
  | LoadStatesErrorAction
  | LoadPrefixesAction
  | LoadPrefixesSuccessAction
  | LoadPrefixesErrorAction
  | LoadDocTypesSuccessAction
  | LoadPhoneTypesAction
  | LoadPhoneTypesSuccessAction
  | LoadPhoneTypesErrorAction
  | LoadDisplayColumnsAction
  | LoadDisplayColumnsSuccessAction
  | LoadDisplayColumnsErrorAction
  | LoadTransactionTypesSuccessAction
  | GetPortalConfigAction
  | GetPortalConfigSuccessAction
  | GetPortalConfigErrorAction
  | ShowSnackbarAction
  | ShowSnackbarErrorAction
  | SetRedirectUrlAction
  | SetApiRootAction
  | SetEnvironmentAction
  | SetEntityTypeAction
  | SetResetPasswordHTMLAction
  | SetCompiledPDFsRootAction
  | ClearStateAction;
