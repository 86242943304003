import {
  Directive,
  HostListener,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { processFilesList } from '../../utils/helper';

@Directive({
  selector: '[marrickSelectFiles]'
})
export class SelectFilesDirective {
  @Input() allowedFileTypes: string[] = [];
  @Output() validFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() invalidFiles: EventEmitter<File[]> = new EventEmitter();

  @HostListener('change', ['$event'])
  onfileChange(event) {
    const files = event.target.files;
    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    // Process list of files from event.
    processFilesList(files, this.allowedFileTypes, validFiles, invalidFiles);

    // Emit the valid and invalid files arrays
    this.validFiles.emit(validFiles);
    this.invalidFiles.emit(invalidFiles);
  }
}
