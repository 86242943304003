import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName][phoneMask]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
  // Create an Observable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  private _preValue: string;

  @Input()
  set preValue(value: string) {
    this._preValue = value ? value : '';
  }

  constructor(
    private el: ElementRef,
    private _phoneControl: NgControl,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.phoneValidate();
  }

  phoneValidate() {
    this._phoneControl.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        // Check for null data
        data = data ? data : '';

        const preInputValue: string = this._preValue;
        const lastChar: string = preInputValue.substr(preInputValue.length - 1);
        // remove all mask characters (keep only numeric values)
        let newVal = data.replace(/\D/g, '');

        let start = this.el.nativeElement.selectionStart;
        let end = this.el.nativeElement.selectionEnd;

        // when removed value from input
        if (data.length < preInputValue.length) {
          // while removing if we encounter ")"" character,
          // then remove the last digit too.
          if (preInputValue.length < start) {
            if (lastChar === ')') {
              newVal = newVal.substr(0, newVal.length - 1);
            }
          }
          // if no number then flush
          if (newVal.length === 0) {
            newVal = '';
          } else if (newVal.length <= 3) {
            // when removing, we change pattern match.
            // otherwise deleting of non-numeric characters is not recognized
            newVal = newVal.replace(/^(\d{0,3})/, '($1');
          } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
          } else {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
          }

          this._phoneControl.control.setValue(newVal, { emitEvent: false });
          // keep cursor the normal position after setting the input above.
          this.renderer
            .selectRootElement(this.el)
            .nativeElement.setSelectionRange(start, end);
          // when typed value in input
        } else {
          const removedD = data.charAt(start);
          // don't show braces for empty value
          if (newVal.length === 0) {
            newVal = '';
          } else if (newVal.length <= 3) {
            // don't show braces for empty groups at the end
            newVal = newVal.replace(/^(\d{0,3})/, '($1)');
          } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
          } else {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
          }
          // check typing whether in middle or not
          // in the following case, you are typing in the middle
          if (preInputValue.length >= start) {
            // change cursor according to special chars.
            if (removedD === '(') {
              start = start + 1;
              end = end + 1;
            }
            if (removedD === ')') {
              start = start + 2; // +2 so there is also space char after ')'.
              end = end + 2;
            }
            if (removedD === '-') {
              start = start + 1;
              end = end + 1;
            }
            if (removedD === ' ') {
              start = start + 1;
              end = end + 1;
            }
            this._phoneControl.control.setValue(newVal, { emitEvent: false });
            this.renderer
              .selectRootElement(this.el)
              .nativeElement.setSelectionRange(start, end);
          } else {
            this._phoneControl.control.setValue(newVal, { emitEvent: false });
            this.renderer
              .selectRootElement(this.el)
              .nativeElement.setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
