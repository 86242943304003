import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'marrick-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss']
})
export class DropZoneComponent implements OnInit {
  @Input() allowedFileTypes: Array<string> = [];
  @Input() allowMultipleFiles: false;
  @Input() dragLeaveColor = '#f5f5f5';
  @Input() dragEnterTextColor = '#ffffff';
  @Input() dragLeaveTextColor = '#808080';
  @Input() dropZoneText = 'DROP OR SELECT FILE HERE';
  @Input() dropZoneTextSize = '100%';

  private _dragEnterColor = '#dbdbdb';

  get dragEnterColor(): string {
    return this._dragEnterColor;
  }

  @Input()
  set dragEnterColor(value: string) {
    this._dragEnterColor = value;
    this.lighterShadeOfEnterColor = this.shadeColor(this._dragEnterColor, 0.2);
  }

  lighterShadeOfEnterColor = '';

  @Output() validFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() invalidFiles: EventEmitter<File[]> = new EventEmitter();

  // Create a local variable to parse the array of allowedFileTypes[] to
  // a string that the OS Open Dialog can use.
  acceptList = '';

  constructor() {}

  ngOnInit() {
    // File Open dialog expects a comma seperated list of extentions.
    this.acceptList = this.allowedFileTypes
      .map(element => '.' + element.toLocaleUpperCase())
      .join(', ');

    if (
      this.dropZoneText === 'DROP OR SELECT FILE HERE' &&
      this.allowMultipleFiles
    ) {
      this.dropZoneText = `DROP OR SELECT ${this.acceptList.toLocaleUpperCase()} FILES HERE`;
    } else if (
      this.dropZoneText === 'DROP OR SELECT FILE HERE' &&
      !this.allowMultipleFiles
    ) {
      this.dropZoneText = `DROP OR SELECT ${this.acceptList.toLocaleUpperCase()} FILE HERE`;
    }
  }

  onDragEnter() {
    // this.setSvgFill(this.lighterShadeOfEnterColor);
  }

  onDragExit() {
    // this.setSvgFill('#ffffff');
  }

  setSvgFill(color: string) {
    // setTimeout(
    //   () => (document.getElementById(this.svgId).style.fill = color),
    //   // () => (document.getElementById('drop-zone-svg').style.fill = color),
    //   0
    // );
  }

  onFilesValid(files: File[]) {
    this.validFiles.emit(files);
  }

  onFilesInvalid(files: File[]) {
    this.invalidFiles.emit(files);
  }

  // https://stackoverflow.com/a/13542669/6214730
  // @param color
  // @param percent
  // @returns {string}

  shadeColor(color, percent) {
    if (color) {
      const f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        // tslint:disable-next-line:no-bitwise
        R = f >> 16,
        // tslint:disable-next-line:no-bitwise
        G = (f >> 8) & 0x00ff,
        // tslint:disable-next-line:no-bitwise
        B = f & 0x0000ff;
      return (
        '#' +
        (
          0x1000000 +
          (Math.round((t - R) * p) + R) * 0x10000 +
          (Math.round((t - G) * p) + G) * 0x100 +
          (Math.round((t - B) * p) + B)
        )
          .toString(16)
          .slice(1)
      );
    } else {
      return '#000000';
    }
  }
}
