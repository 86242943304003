import { Action } from '@ngrx/store';
import { Layout, Nav, Theme } from '../../models';

export enum ActionTypes {
  SetLayout = '[Layout] Set Layout',
  SetLoadingOn = '[Layout] Set Loading On',
  SetLoadingOff = '[Layout] Set Loading Off',
  LoadThemes = '[Layout] Get Themes',
  LoadThemesSuccess = '[Layout] Get Themes Success',
  LoadThemesError = '[Layout] Get Themes Error',
  SetTheme = '[Layout] Set Theme',
  SetThemeName = '[Layout] Set Theme Name',
  ClearState = '[Layout] Clear State',
  SetLogoLoaded = '[Layout] Set Logo Loaded',
  OpenFeedback = '[Layout] Open Feedback',
  SetFeedback = '[Layout] Set Feedback',
  SetFeedbackSuccess = '[Layout] Set Feedback Success',
  SetFeedbackError = '[Layout] Set Feedback Error',
  SendFeedbackEmail = '[Layout] Send Feedback Email',
  SendFeedbackEmailSuccess = '[Layout] Send Feedback Email Success',
  SendFeedbackEmailError = '[Layout] Send Feedback Email Error',
  CancelFeedback = '[Layout] Cancel Feedback'
}

export class SetLayoutAction implements Action {
  readonly type = ActionTypes.SetLayout;
  constructor(public payload: Layout) {}
}

export class SetLoadingOnAction implements Action {
  readonly type = ActionTypes.SetLoadingOn;
}

export class SetLoadingOffAction implements Action {
  readonly type = ActionTypes.SetLoadingOff;
}

export class LoadThemesAction implements Action {
  readonly type = ActionTypes.LoadThemes;
}
export class LoadThemesSuccessAction implements Action {
  readonly type = ActionTypes.LoadThemesSuccess;
  constructor(public payload: Array<Theme>) {}
}
export class LoadThemesErrorAction implements Action {
  readonly type = ActionTypes.LoadThemesError;
  constructor(public payload: any) {}
}

export class SetThemeAction implements Action {
  readonly type = ActionTypes.SetTheme;
  constructor(public payload: Theme) {}
}
export class SetThemeNameAction implements Action {
  readonly type = ActionTypes.SetThemeName;
  constructor(public payload: string) {}
}
export class SetLogoLoadedAction implements Action {
  readonly type = ActionTypes.SetLogoLoaded;
  constructor(public payload: boolean) {}
}
export class OpenFeedbackAction implements Action {
  readonly type = ActionTypes.OpenFeedback;
}
export class SetFeedbackAction implements Action {
  readonly type = ActionTypes.SetFeedback;
  constructor(public payload: any) {}
}
export class SetFeedbackSuccessAction implements Action {
  readonly type = ActionTypes.SetFeedbackSuccess;
}
export class SetFeedbackErrorAction implements Action {
  readonly type = ActionTypes.SetFeedbackError;
  constructor(public payload: any) {}
}

export class SendFeedbackEmailAction implements Action {
  readonly type = ActionTypes.SendFeedbackEmail;
  constructor(public payload: any) {}
}
export class SendFeedbackEmailSuccessAction implements Action {
  readonly type = ActionTypes.SendFeedbackEmailSuccess;
}
export class SendFeedbackEmailErrorAction implements Action {
  readonly type = ActionTypes.SendFeedbackEmailError;
  constructor(public payload: any) {}
}

export class CancelFeedbackAction implements Action {
  readonly type = ActionTypes.CancelFeedback;
}

export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}

export type LayoutActionsUnion =
  | SetLayoutAction
  | SetLoadingOnAction
  | SetLoadingOffAction
  | LoadThemesAction
  | LoadThemesSuccessAction
  | LoadThemesErrorAction
  | SetThemeAction
  | SetThemeNameAction
  | SetLogoLoadedAction
  | OpenFeedbackAction
  | SetFeedbackAction
  | SetFeedbackSuccessAction
  | SetFeedbackErrorAction
  | SendFeedbackEmailAction
  | SendFeedbackEmailSuccessAction
  | SendFeedbackEmailErrorAction
  | CancelFeedbackAction
  | ClearStateAction;
