import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthFacade } from './auth.facade';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from '../services';
import { ErrorService } from '../../core/services';
import { LoggerService } from '../../core/services';
import { ShowSnackbarAction } from '../../+state/app.actions';
import { GoAction } from '../../router/+state/router.actions';
import { User } from 'firebase/app';
import { SnackBarInfo, UserCredential, Organization } from '../../models';
import { AuthService } from '../services';
import { Observable, of, forkJoin, combineLatest } from 'rxjs';
import {
  flatMap,
  map,
  filter,
  exhaustMap,
  catchError,
  switchMap,
  first,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { LogoutPromptComponent } from '../components';
import { MatDialog } from '@angular/material/dialog';
import { SetLoadingOffAction } from '../../layout/+state/layout.actions';
import { SendInviteEmailAction } from '../../credentials/+state/credentials.actions';
import * as fromActions from './auth.actions';

import * as firebase from 'firebase/app';

@Injectable()
export class AuthEffects {
  @Effect()
  authChanged$: Observable<Action> = combineLatest([
    this.afAuth.authState,
    this.afAuth.idTokenResult
  ]).pipe(
    flatMap(([authState, token]) => {
      if (authState && token) {
        const firebaseToken = <User>authState.toJSON();
        const uid = firebaseToken.uid;
        const organizationId = token.claims.organizationId;
        const roles = token.claims.roles;
        const email = token.claims.email;
        const photoUrl = token.claims.photoUrl;
        firebase
          .analytics()
          .logEvent('Authentication', { uid, organizationId, roles });
        return [
          new fromActions.SetAuthenticationAction(true),
          new fromActions.SetUidAction(uid),
          new fromActions.SetOrganizationIdAction(organizationId),
          new fromActions.SetRolesAction(roles),
          new fromActions.SetPhotoUrlAction(photoUrl),
          new fromActions.SetFirebaseUserAction(firebaseToken),
          new fromActions.SetFirebaseTokenAction(token.token),
          new fromActions.GetOrganizationsAction(email),
          new fromActions.UpsertUserAction(<User>{
            ...authState.toJSON(),
            email: email
          })
        ];
      } else {
        firebase.analytics().logEvent('Authentication');
        return [new fromActions.SetAuthenticationAction(false)];
      }
    })
  );

  @Effect()
  setOrganizationId$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.SetOrganizationIdAction>(
      fromActions.ActionTypes.SetOrganizationId
    ),
    map((action: fromActions.SetOrganizationIdAction) => action.payload),
    filter(orgId => !!orgId),
    switchMap(orgId => {
      return this.firestoreUtils.doc$(`organizations/${orgId}`).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        map((organization: Organization) => {
          // Add the profile info to the store.
          return new fromActions.SetOrganizationAction(organization);
        }),
        catchError(err => of(new fromActions.SetOrganizationIdErrorAction(err)))
      );
    })
  );

  @Effect()
  setOrganizationIdError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.SetOrganizationIdErrorAction>(
      fromActions.ActionTypes.SetOrganizationIdError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred with the Organization ID.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  /**
   * getOrganizations$:
   *
   * This Effect() retrieves all Organizations to which the User belongs.
   *
   * 1. Take the user's email address and retrieve the Credentials Doc
   * 2. Iterate over all of the Org Ids in the "roles" map.
   * 3. Retrieve the Organization document for each Organization ID
   * 4. Dispatch an Action to put the array of Organizations into the Store.
   *
   * The result of this process, is the Layout Lib is listening to the
   * dispatched Action and if the User is attached to more than one Organization,
   * the Header bar will enable switching between Organizations
   */
  @Effect()
  getOrganizations$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.GetOrganizationsAction>(
      fromActions.ActionTypes.GetOrganizations
    ),
    map(action => action.payload),
    switchMap(email =>
      this.firestoreUtils.doc$<UserCredential>(`credentials/${email}`).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        switchMap(credential =>
          forkJoin(
            Object.keys(credential.roles).map(orgId => {
              // Retrieve each Organization based on the array of
              // Organization IDs and then return an array of Organizations
              return this.firestoreUtils
                .doc$(`organizations/${orgId}`)
                .pipe(first());
            })
          )
        ),
        map(docs => new fromActions.SetOrganizationsAction(docs)),
        catchError(err => of(new fromActions.GetOrganizationsErrorAction(err)))
      )
    )
  );

  @Effect()
  getOrganizationsError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.GetOrganizationsErrorAction>(
      fromActions.ActionTypes.GetOrganizationsError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred retrieving Organizations.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType<fromActions.LoginAction>(fromActions.ActionTypes.Login),
    map(action => new GoAction({ path: ['/login'] }))
  );

  @Effect()
  loginWithCredentials$ = this.actions$.pipe(
    ofType<fromActions.LoginWithCredentialsAction>(
      fromActions.ActionTypes.LoginWithCredentials
    ),
    switchMap(action =>
      this.authService.login(action.payload).pipe(
        map(res => new fromActions.GetFirebaseCustomTokenAction(res.data.jwt)),
        catchError(err =>
          of(
            new fromActions.LoginWithCredentialsErrorAction({
              ...err,
              uid: action.payload.username // Include the UID in the error object for logging.
            })
          )
        )
      )
    )
  );

  /**
   * API login error:
   *
   * If error.status = 401 then user either typed in their credentials incorrectly
   *  or the username doesn't exist.
   *  => set message to "Check Username and Password" and open SnackBar
   *
   * If error.status = 0 then the API login server is unavailable
   *  => set message to "Login Server Unavailable" and open ErrorSnackBar & log
   *
   * Else
   *  => set message to error.statusText and open ErrorSnackBar & log
   */
  @Effect()
  loginError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LoginWithCredentialsErrorAction>(
      fromActions.ActionTypes.LoginWithCredentialsError
    ),
    switchMap(action => {
      const error = action.payload;
      const uid = action.payload.uid;
      // const errorCode = createUniqueCode();
      // Open a Snackbar to notify the user of the login error.
      let message = 'An error occurred: ';
      // Set the message to the Error's message if it exists.
      if (error && error.payload && error.payload.message) {
        message = error.payload.message;
      }

      // Check is Username was not found and notify user to check
      // their credentials
      if (error.status === 401) {
        message = 'Check Username and Password.';
        const snackBarInfo: SnackBarInfo = {
          message,
          action: 'OK',
          duration: 5000
        };
        this.logger.log(
          `UID: ${uid} ${error.error.message} ${JSON.stringify(error)}`
        );
        return [
          new SetLoadingOffAction(),
          new ShowSnackbarAction(snackBarInfo)
        ];
      } else if (error.status === 0) {
        message = message + 'Login Server Unavailable.';
        this.error.showErrorMessage(uid, message, error);
        return [new SetLoadingOffAction()];
      } else {
        message = message + error.statusText;
        this.error.showErrorMessage(uid, message, error);
        return [new SetLoadingOffAction()];
      }
    })
  );

  @Effect()
  getFirebaseCustomToken$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.GetFirebaseCustomTokenAction>(
      fromActions.ActionTypes.GetFirebaseCustomToken
    ),
    switchMap(action =>
      this.authService.getFirebaseCustomToken(action.payload).pipe(
        map(res => new fromActions.FirebaseSignInAction(res.data)),
        catchError(err =>
          of(new fromActions.GetFirebaseCustomTokenErrorAction(err))
        )
      )
    )
  );

  @Effect()
  getFirebaseCustomTokenError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.GetFirebaseCustomTokenErrorAction>(
      fromActions.ActionTypes.GetFirebaseCustomTokenError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred acquiring custom token.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  @Effect()
  firebaseSignIn$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.FirebaseSignInAction>(
      fromActions.ActionTypes.FirebaseSignIn
    ),
    switchMap(action =>
      this.authService.firebaseAuth(action.payload).pipe(
        map(res => new fromActions.FirebaseSignInSuccessAction(res)),
        catchError(err => of(new fromActions.FirebaseSignInErrorAction(err)))
      )
    )
  );

  @Effect()
  firebaseSignInSuccess$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.FirebaseSignInSuccessAction>(
      fromActions.ActionTypes.FirebaseSignInSuccess
    ),
    map(() => new SetLoadingOffAction())
  );

  @Effect()
  firebaseSignInError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.FirebaseSignInErrorAction>(
      fromActions.ActionTypes.FirebaseSignInError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred connecting to the data store.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  // The Effect responds to the Logout Action fired from the Header Component.
  @Effect()
  logoutConfirmation$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LogoutAction>(fromActions.ActionTypes.Logout),
    exhaustMap(() =>
      // Open a modal dialog to confirm the user wants to logout.
      this.dialogService
        .open(LogoutPromptComponent)
        .afterClosed()
        .pipe(
          map(confirmed => {
            if (confirmed) {
              return new fromActions.LogoutConfirmedAction();
            } else {
              return new fromActions.LogoutCancelledAction();
            }
          })
        )
    )
  );

  // This Effect responds to a Yes answer to the Logout Confirmation dialog.
  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.LogoutConfirmedAction>(
      fromActions.ActionTypes.LogoutConfirmed
    ),
    exhaustMap(() =>
      of(this.authService.logout()).pipe(
        map(() => new fromActions.SetLoggedOutAction()),
        catchError(err => of(new fromActions.LogoutErrorAction(err)))
      )
    )
  );

  @Effect()
  switchOrganization$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.SwitchOrganizationAction>(
      fromActions.ActionTypes.SwitchOrganization
    ),
    switchMap(action =>
      this.authService.switchOrganization(action.payload).pipe(
        map(res => {
          return new fromActions.FirebaseSignInAction(res.data);
        }),
        catchError(err =>
          of(new fromActions.SwitchOrganizationErrorAction(err))
        )
      )
    )
  );

  @Effect()
  switchOrganizationError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.SwitchOrganizationErrorAction>(
      fromActions.ActionTypes.SwitchOrganizationError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred switching organizations.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType<fromActions.ChangePasswordAction>(
      fromActions.ActionTypes.ChangePassword
    ),
    switchMap(action => {
      return this.authService.changePassword(action.payload).pipe(
        map(res => new fromActions.ChangePasswordSuccessAction(res)),
        catchError(err => of(new fromActions.ChangePasswordErrorAction(err)))
      );
    })
  );

  @Effect()
  changePasswordSuccess$ = this.actions$.pipe(
    ofType<fromActions.ChangePasswordSuccessAction>(
      fromActions.ActionTypes.ChangePasswordSuccess
    ),
    switchMap(action => {
      const snackBarInfo: SnackBarInfo = {
        message: 'Password Updated.',
        action: 'OK',
        duration: 3000,
        style: ['success-text']
      };
      return [
        new ShowSnackbarAction(snackBarInfo),
        new GoAction({ path: ['/profile'] })
      ];
    })
  );

  @Effect()
  changePasswordError$ = this.actions$.pipe(
    ofType<fromActions.ChangePasswordErrorAction>(
      fromActions.ActionTypes.ChangePasswordError
    ),
    map(action => {
      const snackBarInfo: SnackBarInfo = {
        message: 'Current Password is incorrect. Password was not changed.',
        action: 'OK',
        duration: 5000,
        style: ['error-text']
      };
      return new ShowSnackbarAction(snackBarInfo);
    })
  );

  // The following 3 Effects responds to the user wanting to reset their password
  // The process will send an email to the user with a
  // one-time reset password token
  @Effect()
  resetPasswordRequest$ = this.actions$.pipe(
    ofType<fromActions.ResetPasswordRequestAction>(
      fromActions.ActionTypes.ResetPasswordRequest
    ),
    switchMap(action => {
      return this.authService.resetPasswordRequest(action.payload).pipe(
        map(res => new fromActions.ResetPasswordRequestSuccessAction(res)),
        catchError(err =>
          of(
            new fromActions.ResetPasswordRequestErrorAction({
              ...err,
              uid: action.payload
            })
          )
        )
      );
    })
  );

  @Effect()
  resetPasswordRequestSuccess$ = this.actions$.pipe(
    ofType<fromActions.ResetPasswordRequestSuccessAction>(
      fromActions.ActionTypes.ResetPasswordRequestSuccess
    ),
    switchMap(action => {
      const snackBarInfo: SnackBarInfo = {
        message: 'Email Sent. Check your email for the Reset Password link.',
        action: 'OK',
        duration: 5000,
        style: ['success-text']
      };
      return [
        new SetLoadingOffAction(),
        new ShowSnackbarAction(snackBarInfo),
        new GoAction({ path: ['/login'] })
      ];
    })
  );

  @Effect()
  resetPasswordRequestError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.ResetPasswordRequestErrorAction>(
      fromActions.ActionTypes.ResetPasswordRequestError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred. Request was not sent.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  // The following 3 Effects are triggered after a user has requested to
  // change their password. The API requires a password and a
  // one-time reset password token
  @Effect()
  resetPassword$ = this.actions$.pipe(
    ofType<fromActions.ResetPasswordAction>(
      fromActions.ActionTypes.ResetPassword
    ),
    switchMap(action => {
      return this.authService.resetPassword(action.payload).pipe(
        map(res => new fromActions.ResetPasswordSuccessAction(res)),
        catchError(err => of(new fromActions.ResetPasswordErrorAction(err)))
      );
    })
  );

  @Effect()
  resetPasswordSuccess$ = this.actions$.pipe(
    ofType<fromActions.ResetPasswordSuccessAction>(
      fromActions.ActionTypes.ResetPasswordSuccess
    ),
    switchMap(action => {
      const snackBarInfo: SnackBarInfo = {
        message: 'Your password has been reset.',
        action: 'OK',
        duration: 5000,
        style: ['success-text']
      };
      return [
        new SetLoadingOffAction(),
        new ShowSnackbarAction(snackBarInfo),
        new GoAction({ path: ['/login'] })
      ];
    })
  );

  @Effect()
  resetPasswordError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.ResetPasswordErrorAction>(
      fromActions.ActionTypes.ResetPasswordError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred. Password was not changed.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  // The following 3 Effects are triggered after an Admin user tries
  // to resend a Portal invite for a user.
  // The process should reset the user's password and then send a portal invite.
  @Effect()
  adminResetPassword$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.AdminResetPasswordAction>(
      fromActions.ActionTypes.AdminResetPassword
    ),
    map(action => action.payload),
    switchMap(user => {
      return this.authService.adminResetPassword(user.email).pipe(
        map(res => {
          user = { ...user, password: res.data.password };
          return new fromActions.AdminResetPasswordSuccessAction(user);
        }),
        catchError(err =>
          of(new fromActions.AdminResetPasswordErrorAction(err))
        )
      );
    })
  );

  @Effect()
  adminResetPasswordSuccess$ = this.actions$.pipe(
    ofType<fromActions.AdminResetPasswordSuccessAction>(
      fromActions.ActionTypes.AdminResetPasswordSuccess
    ),
    map(action => new SendInviteEmailAction(action.payload))
  );

  @Effect()
  adminResetPasswordError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.AdminResetPasswordErrorAction>(
      fromActions.ActionTypes.AdminResetPasswordError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred. User password was not reset.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  /**
   * After a successful auth on the Firebase token, update or
   * insert a document in the users/ collection.
   */
  @Effect()
  upsertUser$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.UpsertUserAction>(fromActions.ActionTypes.UpsertUser),
    map(action => action.payload),
    switchMap(user =>
      this.firestoreUtils.upsert$(`users/${user.email}`, user).pipe(
        takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
        map(() => new fromActions.UpsertUserSuccessAction()),
        catchError(err => of(new fromActions.UpsertUserErrorAction(err)))
      )
    )
  );

  @Effect()
  upsertUserError$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.UpsertUserErrorAction>(
      fromActions.ActionTypes.UpsertUserError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred updating user.';
      this.error.showErrorMessage(uid, message, action.payload);
      return new SetLoadingOffAction();
    })
  );

  @Effect()
  loggedOut$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.SetLoggedOutAction>(
      fromActions.ActionTypes.SetLoggedOut
    ),
    map(action => new fromActions.ClearStateAction())
  );

  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private afAuth: AngularFireAuth,
    private firestoreUtils: FirestoreService,
    private dialogService: MatDialog,
    private authService: AuthService,
    private error: ErrorService,
    private logger: LoggerService
  ) {
    firebase.analytics();
  }
}
