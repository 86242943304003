import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AngularFireModule,
  FirebaseOptionsToken,
  FirebaseAppConfig
} from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirePerformanceModule } from '@angular/fire/performance';

import { AuthRoutingModule } from './auth-routing.module';
import { CoreModule } from '../core/core.module';

// NgRx Store Module imports
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './+state/auth.effects';
import * as fromReducer from './+state/auth.reducer';

import {
  LoginComponent,
  LogoutPromptComponent,
  RequestPasswordResetComponent,
  AccountErrorComponent,
  PasswordResetComponent
} from './components';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutPromptComponent,
    RequestPasswordResetComponent,
    AccountErrorComponent,
    PasswordResetComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    StoreModule.forFeature('auth', fromReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
    ReactiveFormsModule,
    CoreModule
  ],
  entryComponents: [LogoutPromptComponent],
  exports: [AccountErrorComponent, PasswordResetComponent]
})
export class AuthModule {
  // Injected firebase project configuration.
  static forRoot(configFactory: FirebaseAppConfig): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: FirebaseOptionsToken,
          useValue: configFactory
        },
        {
          provide: FirestoreSettingsToken,
          useValue: {}
        }
      ]
    };
  }
}
