import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnChanges
} from '@angular/core';
import { Theme } from '../../../../models';

@Component({
  selector: 'marrick-theme-picker-ui',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ThemePickerComponent implements OnChanges {
  @Input() themeName: string;
  @Input() themes: Array<Theme>;
  @Output() setTheme: EventEmitter<Theme> = new EventEmitter();

  theme: Theme;
  currentTheme: Theme;

  ngOnChanges() {
    this.theme = this.themes.find(theme => theme.name === this.themeName);
    if (!this.theme) {
      return;
    }
    this.currentTheme = this.theme;
  }
}
