import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { AppFacade } from './+state';
import { LayoutFacade } from './layout/+state';
import { Nav, Card } from './models';
import { environment } from '../environments/environment';

@Component({
  selector: 'marrick-root',
  template: `
    <div *ngIf="layout.themeName$ | async as theme">
      <div [class]="theme">
        <div class="mat-app-background">
          <marrick-layout></marrick-layout>
        </div>
      </div>
    </div>
  `
})
export class AppComponent implements OnDestroy {
  // Create an Observable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public app: AppFacade,
    public layout: LayoutFacade,
    private router: Router
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.layout.setLoadingOn();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.layout.setLoadingOff();
          break;
        }
        default: {
          break;
        }
      }
    });

    this.app.loadApiRoot(environment.apiRoot);
    this.app.loadEnvironment(environment.environment);
    this.app.loadEntityType(environment.entityType);
    this.app.loadResetPasswordHTML(environment.resetPasswordHTML);
    this.app.loadCompiledPDFsRoot(environment.compiledPDFsRoot);
    this.app.loadStates();
    this.app.loadPrefixes();
    this.app.loadPhoneTypes();
    this.app.loadPortalConfiguration();
    this.layout.loadThemes();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
