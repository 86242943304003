import { Action } from '@ngrx/store';
import { Preferences } from '../../models';

export enum ActionTypes {
  LoadPreferences = '[Preferences] Load Preferences',
  LoadPreferencesSuccess = '[Preferences] Load Preferences Success',
  LoadPreferencesError = '[Preferences] Load Preferences Error',

  SaveItemsPerPage = '[Preferences] Save ItemsPerPage',
  SaveItemsPerPageSuccess = '[Preferences] Save ItemsPerPage Success',
  SaveItemsPerPageError = '[Preferences] Save ItemsPerPage Error',

  SaveThemeName = '[Preferences] Save Theme Name',
  SaveThemeNameSuccess = '[Preferences] Save Theme Name Success',
  SaveThemeNameError = '[Preferences] Save Theme Name Error',

  Noop = '[Preferences] No Operation',
  ClearState = '[Preferences] Clear State'
}

export class LoadPreferencesAction implements Action {
  readonly type = ActionTypes.LoadPreferences;
}
export class LoadPreferencesSuccessAction implements Action {
  readonly type = ActionTypes.LoadPreferencesSuccess;
  constructor(public payload: Preferences) {}
}
export class LoadPreferencesErrorAction implements Action {
  readonly type = ActionTypes.LoadPreferencesError;
  constructor(public payload: any) {}
}

export class SaveItemsPerPageAction implements Action {
  readonly type = ActionTypes.SaveItemsPerPage;
  constructor(public payload: { [key: string]: number }) {}
}

export class SaveItemsPerPageSuccessAction implements Action {
  readonly type = ActionTypes.SaveItemsPerPageSuccess;
}

export class SaveItemsPerPageErrorAction implements Action {
  readonly type = ActionTypes.SaveItemsPerPageError;
  constructor(public payload: any) {}
}

export class SaveThemeNameAction implements Action {
  readonly type = ActionTypes.SaveThemeName;
  constructor(public payload: string) {}
}

export class SaveThemeNameSuccessAction implements Action {
  readonly type = ActionTypes.SaveThemeNameSuccess;
}

export class SaveThemeNameErrorAction implements Action {
  readonly type = ActionTypes.SaveThemeNameError;
  constructor(public payload: any) {}
}

export class NoopAction implements Action {
  readonly type = ActionTypes.Noop;
}
export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}

export type PreferencesActionsUnion =
  | LoadPreferencesAction
  | LoadPreferencesSuccessAction
  | LoadPreferencesErrorAction
  | SaveItemsPerPageAction
  | SaveItemsPerPageSuccessAction
  | SaveItemsPerPageErrorAction
  | SaveThemeNameAction
  | SaveThemeNameSuccessAction
  | SaveThemeNameErrorAction
  | NoopAction
  | ClearStateAction;
