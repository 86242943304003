// Create a random 6 character string.
// Used for Error Codes.
export function createUniqueCode(): string {
  let code = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';

  for (let i = 0; i < 6; i++) {
    code += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return code;
}
