import { Injectable } from '@angular/core';
import { Observable, of, combineLatest, from } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  filter,
  exhaustMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { FirestoreService, AuthService } from '../../auth/services';
import { AuthFacade } from '../../auth/+state';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActionTypes,
  SetLoggedOutAction
} from '../../auth/+state/auth.actions';
import { FeedbackComponent } from '../components';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromActions from './layout.actions';
import { MatDialog } from '@angular/material';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ShowSnackbarAction } from '../../+state/app.actions';
import { SnackBarInfo } from '../../models';
import { ErrorService } from '../../core/services';
import { SetLoadingOffAction } from '../../layout/+state/layout.actions';

@Injectable()
export class LayoutEffects {
  @Effect()
  getThemes$: Observable<Action> = combineLatest([
    this.actions$.pipe(
      ofType<fromActions.LoadThemesAction>(fromActions.ActionTypes.LoadThemes)
    ),
    this.auth.organizationId$
  ]).pipe(
    filter(([action, orgId]) => !!orgId),
    switchMap(() =>
      this.firestoreUtils
        .col$('themes', ref => ref.where('isActive', '==', true))
        .pipe(
          takeUntil(this.afAuth.authState.pipe(filter(auth => !auth))),
          map(states => new fromActions.LoadThemesSuccessAction(states)),
          catchError(err => of(new fromActions.LoadThemesErrorAction(err)))
        )
    )
  );

  @Effect({ dispatch: false })
  setTheme$ = this.actions$.pipe(
    ofType<fromActions.SetThemeNameAction>(
      fromActions.ActionTypes.SetThemeName
    ),
    map(action => {
      const theme = action.payload;
      const overlayContainerClasses = this.overlayContainer.getContainerElement()
        .classList;
      const themeClassesToRemove = Array.from(overlayContainerClasses).filter(
        (item: string) => item.includes('_theme')
      );
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(theme);
    })
  );

  // The Effect responds to the Logout Action fired from the Header Component.
  @Effect()
  openFeedback$: Observable<Action> = this.actions$.pipe(
    ofType<fromActions.OpenFeedbackAction>(
      fromActions.ActionTypes.OpenFeedback
    ),
    withLatestFrom(this.auth.uid$),
    filter(([action, uid]) => !!uid),
    exhaustMap(([action, uid]) =>
      // Open a modal dialog to confirm the user wants to logout.
      this.dialogService
        .open(FeedbackComponent)
        .afterClosed()
        .pipe(
          map(send => {
            if (send) {
              const feedback = { feedback: send, uid };
              return new fromActions.SetFeedbackAction(feedback);
            } else {
              return new fromActions.CancelFeedbackAction();
            }
          })
        )
    )
  );

  @Effect()
  setFeedback$: Observable<Action> = this.actions$
    .pipe(
      ofType<fromActions.SetFeedbackAction>(fromActions.ActionTypes.SetFeedback)
    )
    .pipe(
      switchMap(action => {
        return from(this.firestoreUtils.add('feedback', action.payload)).pipe(
          map(() => new fromActions.SendFeedbackEmailAction(action.payload)),
          catchError(err => of(new fromActions.SetFeedbackErrorAction(err)))
        );
      })
    );

  @Effect({ dispatch: false })
  saveFeedbackError$ = this.actions$.pipe(
    ofType<fromActions.SetFeedbackErrorAction>(
      fromActions.ActionTypes.SetFeedbackError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred saving feedback. Feedback not sent.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect()
  sendFeedbackEmail$: Observable<Action> = this.actions$
    .pipe(
      ofType<fromActions.SendFeedbackEmailAction>(
        fromActions.ActionTypes.SendFeedbackEmail
      )
    )
    .pipe(
      switchMap(action =>
        this.authService
          .sendFeedback(action.payload.uid, action.payload.feedback)
          .pipe(
            map(() => new fromActions.SendFeedbackEmailSuccessAction()),
            catchError(err =>
              of(new fromActions.SendFeedbackEmailErrorAction(err))
            )
          )
      )
    );

  @Effect({ dispatch: false })
  sendFeedbackEmailError$ = this.actions$.pipe(
    ofType<fromActions.SendFeedbackEmailErrorAction>(
      fromActions.ActionTypes.SendFeedbackEmailError
    ),
    withLatestFrom(this.auth.uid$),
    map(([action, uid]) => {
      const message = 'An error occurred sending feedback. Feedback not sent.';
      this.error.showErrorMessage(uid, message, action.payload);
    })
  );

  @Effect()
  setFeedbackEmailSuccess$: Observable<Action> = this.actions$
    .pipe(
      ofType<fromActions.SendFeedbackEmailSuccessAction>(
        fromActions.ActionTypes.SendFeedbackEmailSuccess
      )
    )
    .pipe(
      map(() => {
        const snackBarInfo: SnackBarInfo = {
          message: `Thank you for providing feedback.`,
          duration: 5000,
          action: 'OK'
        };
        return new ShowSnackbarAction(snackBarInfo);
      })
    );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType<SetLoggedOutAction>(ActionTypes.SetLoggedOut),
    map(action => new fromActions.ClearStateAction())
  );

  constructor(
    private actions$: Actions,
    private firestoreUtils: FirestoreService,
    private overlayContainer: OverlayContainer,
    private auth: AuthFacade,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private dialogService: MatDialog,
    private error: ErrorService
  ) {}
}
