import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, forkJoin, Subject, from } from 'rxjs';
import { filter, takeUntil, tap, catchError, switchMap } from 'rxjs/operators';
import { LayoutFacade } from '../../layout/+state';
import { AppFacade } from '../../+state';
import { AuthFacade } from '../+state';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggerService } from '../../core/services';
import {
  LoginCredential,
  UserCredential,
  Credential,
  ChangePasswordCredential,
  ResetPasswordRequest,
  ResetPassword,
  PortalConfiguration,
  Patient
} from '../../models';

import * as firebase from 'firebase/app';

export interface ApiResponse {
  data: any;
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  // Create an Observable to help manage long lived subscriptions
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Store the API Root address in a subscription from the store
  apiRoot: string;
  firebaseToken: string;
  entityType: string;
  portalConfig: PortalConfiguration;

  // Currently logged in user.
  uid: string;

  // Expose the Firebase Token Observable
  idToken$ = this.afAuth.idTokenResult;

  constructor(
    private router: Router,
    public layout: LayoutFacade,
    private auth: AuthFacade,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private app: AppFacade,
    private logger: LoggerService
  ) {
    // Get the API Root, Entity Type and Firebase Token from the Store.
    this.app.apiRoot$.pipe(takeUntil(this.destroy$)).subscribe(api => {
      this.apiRoot = api;
    });

    this.app.entityType$
      .pipe(
        filter(token => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe(entity => (this.entityType = entity));

    this.app.portalConfiguration$
      .pipe(
        filter(token => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe(config => (this.portalConfig = config));

    this.idToken$
      .pipe(
        filter(token => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe(token => (this.firebaseToken = token.token));

    this.auth.uid$
      .pipe(
        filter(uid => !!uid),
        takeUntil(this.destroy$)
      )
      .subscribe(uid => (this.uid = uid));
  }

  // Login with username and password.
  login(credentials: LoginCredential): Observable<any> {
    this.layout.setLoadingOn();
    return this.http.post<ApiResponse>(
      `${this.apiRoot}/authenticate/user`,
      credentials
    );
  }
  // Get Firebase custom token from API passing in valid JWT
  getFirebaseCustomToken(jwt: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `${jwt}`);
    return this.http.get<ApiResponse>(`${this.apiRoot}/artemis/token`, {
      headers
    });
  }

  // Authenticate with Firebase passing in custom token
  firebaseAuth(mintedToken): Observable<any> {
    return from(
      this.afAuth.auth.signInWithCustomToken(mintedToken).then(res => {
        return this.logger.log(`UID: ${res.user.uid} successfully logged in.`);
      })
    );
  }

  switchOrganization(orgId: string): Observable<any> {
    this.layout.setLoadingOn();
    const headers = this.createApiTokenHeaders();
    const params = new HttpParams().set('org_id', orgId);
    firebase.analytics().logEvent('Switch Organization', {
      uid: this.uid,
      organizationId: orgId
    });

    this.logger.log(`UID: ${this.uid} switching to Organization: ${orgId}.`);
    return this.http.get<ApiResponse>(`${this.apiRoot}/artemis/token`, {
      headers: headers,
      params: params
    });
  }

  /**
   * createApiUser(user)
   *
   * 1. This function expects a fully populated User object.
   * 2. Manufacture the body for the API in the following shape;
   *    {
   *       "email": Username and Email share the same value,
   *       "first_name": First Name,
   *       "last_name": Last Name,
   *       "password": Password in clear text,
   *       "primary_phone": string,
   *       "active": boolean
   *       "group_ids": Array of API group ids
   *     }
   * 3. POST to the API_ROOT/user endpoint sending the body object
   * 3. The endpoint will accept either an API JWT or a Firebase JWT
   * 4. If successful, dispatch an Action to create a credentials document listing the
   *    new user's roles
   */
  createApiUser(user: UserCredential): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders()
    };

    return this.http
      .get<ApiResponse>(
        `${this.apiRoot}/artemis/user-access/random-password`,
        httpOptions
      )
      .pipe(
        switchMap(res => {
          const newUser = { ...user, password: res.data };
          return this.http
            .post<ApiResponse>(`${this.apiRoot}/users`, newUser, httpOptions)
            .pipe(
              tap(() =>
                this.logger.log(
                  `UID: ${this.uid} Created API User: ${user.email} Default Organization ID: ${user.defaultOrganizationId}`
                )
              ),
              catchError(err => {
                this.logger.error(
                  `UID: ${this.uid} Create API User error ${JSON.stringify(
                    err
                  )}`
                );
                throw err;
              })
            );
        })
      );
  }

  changePassword(
    credentials: ChangePasswordCredential
  ): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders()
    };
    return this.http
      .put<ApiResponse>(
        `${this.apiRoot}/users/password`,
        credentials,
        httpOptions
      )
      .pipe(
        tap(() =>
          this.logger.log(`UID: ${credentials.username} changed password.`)
        ),
        catchError(err => {
          this.logger.error(
            `UID: ${
              credentials.username
            } change password error, original password was incorrect. ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  resetPasswordRequest(
    requestBody: ResetPasswordRequest
  ): Observable<ApiResponse> {
    this.layout.setLoadingOn();
    const body = { type: 'sharescape', ...requestBody };
    return this.http
      .post<ApiResponse>(`${this.apiRoot}/users/password/reset`, body)
      .pipe(
        tap(() =>
          this.logger.log(`UID: ${body.username} sent reset password request.`)
        ),
        catchError(err => {
          this.logger.error(
            `UID: ${
              body.username
            } reset password request error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  resetPassword(body: ResetPassword): Observable<ApiResponse> {
    return this.http
      .put<ApiResponse>(`${this.apiRoot}/users/password/reset`, body)
      .pipe(
        tap(() => this.logger.log(`User reset password. Token: ${body.token}`)),
        catchError(err => {
          this.logger.error(
            `User password reset error. Token: ${body.token} ${JSON.stringify(
              err
            )}`
          );
          throw err;
        })
      );
  }

  // An Admin user can reset a regular user's password.
  // This function is used during the Resend Portal invite flow.
  adminResetPassword(username: string): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders()
    };

    return this.http
      .get<ApiResponse>(
        `${this.apiRoot}/artemis/user-access/random-password`,
        httpOptions
      )
      .pipe(
        switchMap(res => {
          const body = { username, password: res.data };
          return this.http
            .post<ApiResponse>(
              `${this.apiRoot}/artemis/user-access/reset`,
              body,
              httpOptions
            )
            .pipe(
              tap(() =>
                this.logger.log(
                  `UID: ${this.uid} reset the password and sent a portal invite email for ${username}`
                )
              ),
              catchError(err => {
                this.logger.error(
                  `UID: ${this.uid} admin password reset error ${JSON.stringify(
                    err
                  )}`
                );
                throw err;
              })
            );
        })
      );
  }

  toggleApiUserActive(user: Credential): Observable<ApiResponse> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('username', user.id)
    };

    let url = `${this.apiRoot}/artemis/user-access/`;
    let message = `UID: ${this.uid} set user: ${user.id} `;
    if (user.isActive) {
      // Trying to enable user
      url = url + 'grant';
      message = message + `to Active.`;
    } else {
      // Trying to disable user
      url = url + 'revoke';
      message = message + `to Inactive.`;
    }
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(res => this.logger.log(message)),
      catchError(err => {
        this.logger.error(
          `UID: ${this.uid} Set User Active error ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  getUserByEmail(email: string): Observable<any> {
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('username', email)
    };
    return this.http
      .get<ApiResponse>(`${this.apiRoot}/users/username/exists`, httpOptions)
      .pipe(
        catchError(err => {
          this.logger.error(
            `UID: ${this.uid} getUserByEmail error ${JSON.stringify(err)}`
          );
          throw err;
        })
      );
  }

  filesUpload(
    files: File[],
    patientId: string,
    docType: string
  ): Observable<any> {
    return forkJoin(
      files.map(file => {
        const fileName = file.name;
        const formData = new FormData();
        formData.append(fileName, file);

        // Do not specify a Content-Type so multi-part/form
        const headers = this.createApiTokenHeaders();

        const params = new HttpParams()
          .set('fileName', fileName)
          .set('contentType', file.type)
          .set('patientId', patientId)
          .set('docType', docType)
          .set('isActive', 'true');

        const httpOptions = {
          headers: headers,
          params: params
        };

        return this.http.post(
          `${this.apiRoot}/artemis/file`,
          formData,
          httpOptions
        );
      })
    ).pipe(
      catchError(err => {
        this.logger.error(
          `UID: ${this.uid} filesUpload error ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  sendInvite(user: UserCredential): Observable<any> {
    // Configure Header
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers
    };

    // Create Body
    let portalInviteHTML = this.portalConfig.portalInviteHTML;
    portalInviteHTML = portalInviteHTML.replace(
      /PORTAL_NAME/g,
      this.portalConfig.name
    );
    portalInviteHTML = portalInviteHTML.replace(/USERNAME/g, user.email);
    portalInviteHTML = portalInviteHTML.replace(/PASSWORD/g, user.password);
    portalInviteHTML = portalInviteHTML.replace(/URL/g, this.portalConfig.host);
    portalInviteHTML = portalInviteHTML.replace(
      /CONTACT/g,
      this.portalConfig.contact
    );

    const body = {
      recipients: [user.email],
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Invite`,
      body: portalInviteHTML,
      type: 'sharescape'
    };

    return this.http.post(`${this.apiRoot}/email/v2`, body, httpOptions);
  }

  sendAddOrgEmail(email: string): Observable<any> {
    // Configure Header
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers
    };

    // Create Body
    let portalHTML = this.portalConfig.portalAddOrgHTML;
    portalHTML = portalHTML.replace(/PORTAL_NAME/g, this.portalConfig.name);
    portalHTML = portalHTML.replace(/URL/g, this.portalConfig.host);

    const body = {
      recipients: [email],
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Invite`,
      body: portalHTML,
      type: 'sharescape'
    };

    return this.http.post(`${this.apiRoot}/email/v2`, body, httpOptions);
  }

  sendFeedback(user, feedback: any): Observable<any> {
    const headers = this.createApiTokenHeaders();
    const httpOptions = {
      headers: headers
    };

    let body = this.portalConfig.feedbackMessage;
    body = body.replace(/USER/g, user);
    body = body.replace(/FEEDBACK/g, feedback);

    const message = {
      recipients: this.portalConfig.feedbackRecipients,
      from: this.portalConfig.name,
      subject: `${this.portalConfig.name} Feedback`,
      type: 'sharescape',
      body
    };

    return this.http
      .post(`${this.apiRoot}/email/v2`, message, httpOptions)
      .pipe(tap(() => this.logger.log(`UID: ${user} provided feedback.`)));
  }

  importPatient(accountNo: string): Observable<any> {
    this.layout.setLoadingOn();
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', accountNo)
        .set('location_id', this.portalConfig.locationId)
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/import`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Imported Patient - Account Number: ${accountNo} Location ID: ${this.portalConfig.locationId}`
        );
      }),
      catchError(err => {
        this.logger.error(
          `UID: ${this.uid} Import Patient failed. Account Number: ${accountNo} Location ID: ${this.portalConfig.locationId}`
        );
        throw err;
      })
    );
  }

  reImportPatient(patient: Patient): Observable<any> {
    const locationId = patient.id.split(':')[0];
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', patient.accountNo)
        .set('location_id', locationId)
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/import`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Imported Patient - Account Number: ${patient.accountNo} Location ID: ${this.portalConfig.locationId}`
        );
      }),
      catchError(err => {
        this.logger.error(
          `UID: ${this.uid} Import Patient failed. Account Number: ${patient.accountNo} Location ID: ${this.portalConfig.locationId}`
        );
        throw err;
      })
    );
  }

  refreshPatient(patient: Patient): Observable<any> {
    this.layout.setLoadingOn();
    const httpOptions = {
      headers: this.createApiTokenHeaders()
    };
    const parts = patient.id.split(':');
    const location_id = parts[0];
    const patient_id = parseInt(parts[1], 10);
    const body = {
      location_id,
      patient_id
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/record-sync`;
    return this.http.post<ApiResponse>(url, body, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Refreshed Patient - Patient ID: ${patient.id}`
        );
      })
    );
  }

  refreshPatientDocuments(accountNo: string): Observable<any> {
    this.layout.setLoadingOn();
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams()
        .set('account_number', accountNo)
        .set('location_id', this.portalConfig.locationId)
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/docs/sync`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Refreshed Patient Documents - Account Number: ${accountNo} Location ID: ${this.portalConfig.locationId}`
        );
      })
    );
  }

  removePatient(patient: Patient): Observable<any> {
    this.layout.setLoadingOn();
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('patient_id', patient.id)
    };
    const url = `${this.apiRoot}/artemis/health-quest/patient/clear`;
    return this.http.post<ApiResponse>(url, null, httpOptions).pipe(
      tap(() => {
        this.logger.log(
          `UID: ${this.uid} Removed Patient - Patient ID: ${patient.id}`
        );
      })
    );
  }

  compilePDFs(documentIds: string[]): Observable<ApiResponse> {
    this.layout.setLoadingOn();
    const httpOptions = {
      headers: this.createApiTokenHeaders(),
      params: new HttpParams().set('documents', JSON.stringify(documentIds))
    };
    return this.http
      .post<ApiResponse>(
        `${this.apiRoot}/artemis/docs/merge`,
        null,
        httpOptions
      )
      .pipe(
        tap(() => {
          this.layout.setLoadingOff();
          this.logger.log(
            `UID: ${this.uid} compiled and downloaded ${documentIds.join()}`
          );
        })
      );
  }

  getFileUrl(path: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${this.apiRoot}${path}&authorization=${this.firebaseToken}`
    );
  }

  logout() {
    this.logger.log(`UID: ${this.uid} successfully logged out.`);
    // Sign out of Firebase
    this.afAuth.auth.signOut();
    // Return to homepage
    this.router.navigate(['login']);
  }

  createApiTokenHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', this.firebaseToken)
      .set('Entity-Type', this.entityType);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
