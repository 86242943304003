import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Nav } from '../../../models';

@Component({
  selector: 'marrick-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.scss']
})
export class NavTreeComponent {
  @Input()
  auth: boolean;
  @Input()
  roles: Array<string>;
  @Input()
  contact: string;
  @Input()
  requestAppointment: string;
  @Input()
  url: string;
  @Input()
  organizationLogoUrl: string;
  @Input()
  navItems: Array<Nav>;
  @Input()
  isLogoLoaded: boolean;

  @Output() click: EventEmitter<Nav> = new EventEmitter();
  @Output() logoLoaded: EventEmitter<boolean> = new EventEmitter();

  imageLoaded() {
    this.logoLoaded.emit(true);
  }
}
