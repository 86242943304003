// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCjOWDtnMVlUCFc6XpA9QPNqMiLU7APpwc',
    authDomain: 'firestore-demo-artemis-a9f4f.firebaseapp.com',
    databaseURL: 'https://firestore-demo-artemis-a9f4f.firebaseio.com',
    projectId: 'firestore-demo-artemis-a9f4f',
    storageBucket: 'firestore-demo-artemis-a9f4f.appspot.com',
    messagingSenderId: '822530225263',
    appId: '1:822530225263:web:aef5b4af3b4aa7f206499a',
    measurementId: 'G-HEVNT848JG',
  },
  apiRoot: 'https://demo-artemis.marrick.com',
  entityType: '41c293a2-28af-11e9-ac23-12b06c210614',
  compiledPDFsRoot: '/s3/stream?bucket=documentgeneration&download=1&key=',
  // tslint:disable-next-line:max-line-length
  resetPasswordHTML: `You have requested to reset your password for the Portal.<br><br> <a href="https://demo.sharescape.org/password-reset?t=%s">Follow this link to reset your password.</a> <br><br>If you didn't request your password to be reset, you can ignore this email. <br><br>Kind Regards,<br><br>Portal Administrator`,

  logging: {
    // disableConsoleLogging: true,
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.LOG,
    serverLoggingUrl: 'https://demo-artemis.marrick.com/artemis/logs',
  },
  environment: 'DEMO',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
