import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marrick-account-error',
  templateUrl: './account-error.component.html',
  styleUrls: ['./account-error.component.scss']
})
export class AccountErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
