import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Layout, Organization } from '../../../../models';

@Component({
  selector: 'marrick-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input()
  defaultTitle: string;
  @Input()
  auth: string;
  @Input()
  roles: Array<string>;
  @Input()
  organizationId: string;
  @Input()
  organizations: Array<Organization>;
  @Input()
  organizationName: string;
  @Input()
  layout: Layout;
  @Input()
  photoUrl: string;

  @Output()
  toggleSideNav: EventEmitter<void> = new EventEmitter();
  @Output()
  switchOrganization: EventEmitter<string> = new EventEmitter();
  @Output()
  login: EventEmitter<void> = new EventEmitter();
  @Output()
  logout: EventEmitter<void> = new EventEmitter();
  @Output()
  organization: EventEmitter<string> = new EventEmitter();

  onOrganization() {
    let path = `/organizations/user/${this.organizationId}/details`;
    if (this.roles.includes('admin')) {
      path = path + '/edit';
    }
    this.organization.emit(path);
  }

  displayHeading(): string {
    if (!this.organizationName) {
      return `Portal`;
    } else {
      return `${this.organizationName} Portal`;
    }
  }
}
