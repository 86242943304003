import { Action } from '@ngrx/store';
import { User } from 'firebase/app';
import {
  UserCredential,
  LoginCredential,
  ChangePasswordCredential,
  ResetPasswordRequest,
  ResetPassword,
  Organization
} from '../../models';

export enum ActionTypes {
  // Authentication Actions
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  SetLoggedIn = '[Auth] Set Logged In',
  SetLoggedOut = '[Auth] Set Logged Out',

  // Store Data
  SetAuthentication = '[Auth] Set Authentication',
  SetUid = '[Auth] Set UID',
  SetRoles = '[Auth] Set Roles',
  SetOrganizationId = '[Auth] Set Organization Id',
  SetOrganizationIdError = '[Auth] Set Organization Id Error',
  SetOrganization = '[Auth] Set Organization',

  // Password Management
  ChangePassword = '[Auth] Change Password',
  ChangePasswordSuccess = '[Auth] Change Password Success',
  ChangePasswordError = '[Auth] Change Password Error',

  ResetPasswordRequest = '[Auth] Reset Password Request',
  ResetPasswordRequestSuccess = '[Auth] Reset Password Request Success',
  ResetPasswordRequestError = '[Auth] Reset Password Request Error',

  ResetPassword = '[Auth] Reset Password',
  ResetPasswordSuccess = '[Auth] Reset Password Success',
  ResetPasswordError = '[Auth] Reset Password Error',

  AdminResetPassword = '[Auth] Admin Reset Password',
  AdminResetPasswordSuccess = '[Auth] Admin Reset Password Success',
  AdminResetPasswordError = '[Auth] Admin Reset Password Error',

  SetFirebaseToken = '[Auth] Set Firebase Token',
  SetFirebaseUser = '[Auth] Set Firebase User',
  SetEmailAddress = '[Auth] Set Email Address',
  SetPhotoUrl = '[Auth] Set Photo URL',

  GetOrganizations = '[Auth] Get Organizations',
  GetOrganizationsError = '[Auth] Get Organizations Error',
  SetOrganizations = '[Auth] Set Organizations',

  // Switch Organization
  SwitchOrganization = '[Auth] Switch Organization Id',
  SwitchOrganizationSuccess = '[Auth] Switch Organization Id Success',
  SwitchOrganizationError = '[Auth] Switch Organization Id Error',

  UpdateAPIUserPassword = '[Auth] Update API User Password',
  UpdateAPIUserPasswordSuccess = '[Auth] Update API User Password Success',
  UpdateAPIUserPasswordError = '[Auth] Update API User Password Error',

  // Internal Process Actions
  LoginWithCredentials = '[Auth] Login With Credentials',
  LoginWithCredentialsError = '[Auth] Login With Credentials Error',
  LoginComplete = '[Auth] Login Complete',
  LoginSuccess = '[Auth] Login Success',
  LoginError = '[Auth] Login Error',
  LoginVerify = '[Auth] Login Verify',

  // Get Firebase Custom Token from API
  GetFirebaseCustomToken = '[Auth] Get Custom Token',
  GetFirebaseCustomTokenSuccess = '[Auth] Get Custom Token Success',
  GetFirebaseCustomTokenError = '[Auth] Get Custom Token Error',

  // Firebase Auth
  FirebaseSignIn = '[Auth] Firebase Sign In',
  FirebaseSignInSuccess = '[Auth] Firebase Sign In Success',
  FirebaseSignInError = '[Auth] Firebase Sign In Error',

  // Persist Firebase token information to Firestore
  UpsertUser = '[Auth] Upsert User Information',
  UpsertUserSuccess = '[Auth] Upsert User Information Success',
  UpsertUserError = '[Auth] Upsert User Information Error',

  LogoutConfirmed = '[Auth] Logout Confirmed',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutCancelled = '[Auth] Logout Cancelled',
  LogoutError = '[Auth] Logout Error',

  ClearState = '[Auth] Clear State',

  Error = '[Auth] Error'
}

export class SetAuthenticationAction implements Action {
  readonly type = ActionTypes.SetAuthentication;
  constructor(public payload: boolean) {}
}

export class SetUidAction implements Action {
  readonly type = ActionTypes.SetUid;
  constructor(public payload: string) {}
}

export class SetRolesAction implements Action {
  readonly type = ActionTypes.SetRoles;
  constructor(public payload: Array<string>) {}
}
export class SetOrganizationIdAction implements Action {
  readonly type = ActionTypes.SetOrganizationId;
  constructor(public payload: string) {}
}
export class SetOrganizationIdErrorAction implements Action {
  readonly type = ActionTypes.SetOrganizationIdError;
  constructor(public payload: any) {}
}
export class SetOrganizationAction implements Action {
  readonly type = ActionTypes.SetOrganization;
  constructor(public payload: Organization) {}
}

export class SetPhotoUrlAction implements Action {
  readonly type = ActionTypes.SetPhotoUrl;
  constructor(public payload: string) {}
}

export class SetLoggedInAction implements Action {
  readonly type = ActionTypes.SetLoggedIn;
}
export class SetLoggedOutAction implements Action {
  readonly type = ActionTypes.SetLoggedOut;
}

export class GetOrganizationsAction implements Action {
  readonly type = ActionTypes.GetOrganizations;
  constructor(public payload: string) {}
}
export class GetOrganizationsErrorAction implements Action {
  readonly type = ActionTypes.GetOrganizationsError;
  constructor(public payload: any) {}
}

export class SetOrganizationsAction implements Action {
  readonly type = ActionTypes.SetOrganizations;
  constructor(public payload: Array<Organization>) {}
}

export class SwitchOrganizationAction implements Action {
  readonly type = ActionTypes.SwitchOrganization;
  constructor(public payload: string) {}
}
export class SwitchOrganizationSuccessAction implements Action {
  readonly type = ActionTypes.SwitchOrganizationSuccess;
}
export class SwitchOrganizationErrorAction implements Action {
  readonly type = ActionTypes.SwitchOrganizationError;
  constructor(public payload: any) {}
}

export class UpdateApiUserPasswordAction implements Action {
  readonly type = ActionTypes.UpdateAPIUserPassword;
  constructor(public payload: UserCredential) {}
}
export class UpdateApiUserPasswordSuccessAction implements Action {
  readonly type = ActionTypes.UpdateAPIUserPasswordSuccess;
  constructor(public payload: any) {}
}
export class UpdateApiUserPasswordErrorAction implements Action {
  readonly type = ActionTypes.UpdateAPIUserPasswordError;
  constructor(public payload?: any) {}
}

export class UpsertUserAction implements Action {
  readonly type = ActionTypes.UpsertUser;
  constructor(public payload: User) {}
}
export class UpsertUserSuccessAction implements Action {
  readonly type = ActionTypes.UpsertUserSuccess;
}
export class UpsertUserErrorAction implements Action {
  readonly type = ActionTypes.UpsertUserError;
  constructor(public payload?: any) {}
}
export class SetFirebaseTokenAction implements Action {
  readonly type = ActionTypes.SetFirebaseToken;
  constructor(public payload: string) {}
}
export class SetFirebaseUserAction implements Action {
  readonly type = ActionTypes.SetFirebaseUser;
  constructor(public payload: User) {}
}

export class SetEmailAddressAction implements Action {
  readonly type = ActionTypes.SetEmailAddress;
  constructor(public payload: string) {}
}

export class LoginAction implements Action {
  readonly type = ActionTypes.Login;
}

export class LoginWithCredentialsAction implements Action {
  readonly type = ActionTypes.LoginWithCredentials;
  constructor(public payload: LoginCredential) {}
}
export class LoginWithCredentialsErrorAction implements Action {
  readonly type = ActionTypes.LoginWithCredentialsError;
  constructor(public payload: any) {}
}

export class LoginCompleteAction implements Action {
  readonly type = ActionTypes.LoginComplete;
}

export class LoginSuccessAction implements Action {
  readonly type = ActionTypes.LoginSuccess;
}

export class LoginErrorAction implements Action {
  readonly type = ActionTypes.LoginError;
  constructor(public payload?: any) {}
}

export class LoginVerifyAction implements Action {
  readonly type = ActionTypes.LoginVerify;
}

export class GetFirebaseCustomTokenAction implements Action {
  readonly type = ActionTypes.GetFirebaseCustomToken;
  constructor(public payload?: any) {}
}
export class GetFirebaseCustomTokenSuccessAction implements Action {
  readonly type = ActionTypes.GetFirebaseCustomTokenSuccess;
  constructor(public payload?: any) {}
}
export class GetFirebaseCustomTokenErrorAction implements Action {
  readonly type = ActionTypes.GetFirebaseCustomTokenError;
  constructor(public payload?: any) {}
}

export class FirebaseSignInAction implements Action {
  readonly type = ActionTypes.FirebaseSignIn;
  constructor(public payload?: any) {}
}
export class FirebaseSignInSuccessAction implements Action {
  readonly type = ActionTypes.FirebaseSignInSuccess;
  constructor(public payload?: any) {}
}
export class FirebaseSignInErrorAction implements Action {
  readonly type = ActionTypes.FirebaseSignInError;
  constructor(public payload?: any) {}
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.Logout;
}

export class LogoutConfirmedAction implements Action {
  readonly type = ActionTypes.LogoutConfirmed;
}

export class LogoutSuccessAction implements Action {
  readonly type = ActionTypes.LogoutSuccess;
}

export class LogoutCancelledAction implements Action {
  readonly type = ActionTypes.LogoutCancelled;
}

export class LogoutErrorAction implements Action {
  readonly type = ActionTypes.LogoutError;
  constructor(public payload?: any) {}
}

export class ChangePasswordAction implements Action {
  readonly type = ActionTypes.ChangePassword;
  constructor(public payload: ChangePasswordCredential) {}
}
export class ChangePasswordSuccessAction implements Action {
  readonly type = ActionTypes.ChangePasswordSuccess;
  constructor(public payload: any) {}
}
export class ChangePasswordErrorAction implements Action {
  readonly type = ActionTypes.ChangePasswordError;
  constructor(public payload?: any) {}
}
export class ResetPasswordRequestAction implements Action {
  readonly type = ActionTypes.ResetPasswordRequest;
  constructor(public payload: ResetPasswordRequest) {}
}
export class ResetPasswordRequestSuccessAction implements Action {
  readonly type = ActionTypes.ResetPasswordRequestSuccess;
  constructor(public payload: any) {}
}
export class ResetPasswordRequestErrorAction implements Action {
  readonly type = ActionTypes.ResetPasswordRequestError;
  constructor(public payload?: any) {}
}
export class ResetPasswordAction implements Action {
  readonly type = ActionTypes.ResetPassword;
  constructor(public payload: ResetPassword) {}
}
export class ResetPasswordSuccessAction implements Action {
  readonly type = ActionTypes.ResetPasswordSuccess;
  constructor(public payload: any) {}
}
export class ResetPasswordErrorAction implements Action {
  readonly type = ActionTypes.ResetPasswordError;
  constructor(public payload?: any) {}
}

export class AdminResetPasswordAction implements Action {
  readonly type = ActionTypes.AdminResetPassword;
  constructor(public payload: UserCredential) {}
}

export class AdminResetPasswordSuccessAction implements Action {
  readonly type = ActionTypes.AdminResetPasswordSuccess;
  constructor(public payload: any) {}
}

export class AdminResetPasswordErrorAction implements Action {
  readonly type = ActionTypes.AdminResetPasswordError;
  constructor(public payload: any) {}
}
export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}
export class ErrorAction implements Action {
  readonly type = ActionTypes.Error;
  constructor(public payload?: any) {}
}
export type ActionsUnion =
  | SetAuthenticationAction
  | SetUidAction
  | SetRolesAction
  | SetOrganizationIdAction
  | SetOrganizationIdErrorAction
  | SetOrganizationAction
  | SetFirebaseTokenAction
  | SetFirebaseUserAction
  | SetEmailAddressAction
  | SetPhotoUrlAction
  | GetOrganizationsAction
  | GetOrganizationsErrorAction
  | SetOrganizationsAction
  | SwitchOrganizationAction
  | SwitchOrganizationSuccessAction
  | SwitchOrganizationErrorAction
  | UpdateApiUserPasswordAction
  | UpdateApiUserPasswordSuccessAction
  | UpdateApiUserPasswordErrorAction
  | UpsertUserAction
  | UpsertUserSuccessAction
  | UpsertUserErrorAction
  | LoginAction
  | LoginWithCredentialsAction
  | LoginWithCredentialsErrorAction
  | LoginCompleteAction
  | LoginSuccessAction
  | LoginErrorAction
  | LoginVerifyAction
  | GetFirebaseCustomTokenAction
  | GetFirebaseCustomTokenSuccessAction
  | GetFirebaseCustomTokenErrorAction
  | FirebaseSignInAction
  | FirebaseSignInSuccessAction
  | FirebaseSignInErrorAction
  | LogoutAction
  | LogoutConfirmedAction
  | LogoutSuccessAction
  | LogoutCancelledAction
  | LogoutErrorAction
  | ChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordErrorAction
  | ResetPasswordRequestAction
  | ResetPasswordRequestSuccessAction
  | ResetPasswordRequestErrorAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordErrorAction
  | AdminResetPasswordAction
  | AdminResetPasswordSuccessAction
  | AdminResetPasswordErrorAction
  | ClearStateAction
  | ErrorAction;
