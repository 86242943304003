import { Injectable } from '@angular/core';
import { FirestoreService } from '../../auth/services/firestore.service';
import { Observable } from 'rxjs';
import { Preferences } from '../../models';
@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  constructor(private firestoreUtils: FirestoreService) {}

  retrievePreferences(uid: string): Observable<any> {
    return this.firestoreUtils.docWithoutId$<Preferences>(`preferences/${uid}`);
  }

  persistPreferences(uid: string, preferences: Preferences): Observable<any> {
    return this.firestoreUtils.upsert$(`preferences/${uid}`, preferences);
  }
}
