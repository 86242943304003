import { Component, Input } from '@angular/core';
import { Layout } from 'src/app/models';
import { LayoutFacade } from '../../+state';

@Component({
  selector: 'marrick-footer',
  template: `
    <mat-toolbar class="footer">
      <a
        *ngIf="contact"
        mat-flat-button
        [href]="contact"
        [ngClass]="{
          'hide-column': layout.handset && !layout.landscape
        }"
        target="_blank"
        >Contact Us</a
      >
      <a
        *ngIf="privacyPolicy"
        mat-flat-button
        [href]="privacyPolicy"
        target="_blank"
        >Privacy Policy</a
      >
      <a
        *ngIf="termsOfService"
        mat-flat-button
        [href]="termsOfService"
        target="_blank"
        >Terms of Service</a
      >
      <span class="fill-remaining-space"></span>
      <a
        mat-flat-button
        (click)="onOpenFeedback()"
        [ngClass]="{
          'hide-column': layout.handset && !layout.landscape
        }"
        >Send Feedback</a
      >
      <mat-icon>copyright</mat-icon>{{ year }} {{ getCopyright() }}
    </mat-toolbar>
  `,
  styles: [
    `
      .footer {
        height: 40px;
        font-size: 12px;
      }

      .fill-remaining-space {
        flex: 1 1 auto;
      }

      mat-icon {
        font-size: 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
      }

      .mat-flat-button {
        font-size: 12px;
        background-color: transparent;
      }

      .hide-column {
        display: none;
      }
    `
  ]
})
export class FooterComponent {
  @Input()
  contact: string;
  @Input()
  privacyPolicy: string;
  @Input()
  termsOfService: string;
  @Input()
  layout: Layout;
  @Input()
  year: string;

  constructor(private layoutFacade: LayoutFacade) {}

  getCopyright() {
    return 'ShareScape LLC';
  }

  onOpenFeedback() {
    this.layoutFacade.openFeedback();
  }
}
