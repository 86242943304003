import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './layout.reducer';

export const selectLayoutState = createFeatureSelector<fromReducer.State>(
  'layout'
);

export const selectLoading = createSelector(
  selectLayoutState,
  state => state.isLoading
);

export const selectLayout = createSelector(
  selectLayoutState,
  state => state.layout
);

export const selectHandset = createSelector(
  selectLayout,
  state => state.handset
);

export const selectTable = createSelector(selectLayout, state => state.tablet);

export const selectWeb = createSelector(selectLayout, state => state.web);

export const selectPortrait = createSelector(
  selectLayout,
  state => state.portrait
);

export const selectLandscape = createSelector(
  selectLayout,
  state => state.landscape
);

export const selectThemeName = createSelector(
  selectLayoutState,
  state => state.themeName
);

export const selectSelectedTheme = createSelector(
  selectLayoutState,
  state => state.selectedTheme
);

export const selectThemes = createSelector(
  selectLayoutState,
  state => state.themes
);

export const selectLogoLoaded = createSelector(
  selectLayoutState,
  state => state.logoLoaded
);

export const selectFeedback = createSelector(
  selectLayoutState,
  state => state.feedback
);
