import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppFacade } from '../../../+state/app.facade';
import { AuthFacade } from '../../+state';
import { first } from 'rxjs/operators';
import { ResetPasswordRequest } from '../../../models';

@Component({
  selector: 'marrick-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent {
  constructor(private app: AppFacade, public auth: AuthFacade) {}

  form: FormGroup = new FormGroup({
    username: new FormControl('')
  });

  get username() {
    return this.form.get('username').value;
  }

  onResetPassword(username: string) {
    this.app.resetPasswordHTML$.pipe(first()).subscribe(html => {
      const body: ResetPasswordRequest = {
        username: username,
        html: html
      };
      this.auth.resetPasswordRequest(body);
    });
  }
}
