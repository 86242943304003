import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromActions from './layout.actions';
import * as fromReducer from './layout.reducer';
import * as fromSelectors from './layout.selectors';

import { Layout, Theme, Nav } from '../../models';

@Injectable({ providedIn: 'root' })
export class LayoutFacade {
  layout$: Observable<Layout> = this.store.pipe(
    select(fromSelectors.selectLayout)
  );

  theme$: Observable<Theme> = this.store.pipe(
    select(fromSelectors.selectSelectedTheme)
  );

  themes$: Observable<Array<Theme>> = this.store.pipe(
    select(fromSelectors.selectThemes)
  );

  themeName$: Observable<string> = this.store.pipe(
    select(fromSelectors.selectThemeName)
  );

  isLoading$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLoading)
  );

  logoLoaded$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLogoLoaded)
  );

  feedback$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectFeedback)
  );

  constructor(private store: Store<fromReducer.State>) {}

  setLayout(layout: Layout) {
    this.store.dispatch(new fromActions.SetLayoutAction(layout));
  }

  setLoadingOn() {
    this.store.dispatch(new fromActions.SetLoadingOnAction());
  }

  setLoadingOff() {
    this.store.dispatch(new fromActions.SetLoadingOffAction());
  }

  loadThemes() {
    this.store.dispatch(new fromActions.LoadThemesAction());
  }

  setTheme(theme: Theme) {
    this.store.dispatch(new fromActions.SetThemeAction(theme));
  }

  setThemeName(theme: string) {
    this.store.dispatch(new fromActions.SetThemeNameAction(theme));
  }

  setLogoLoading(isLoading: boolean) {
    this.store.dispatch(new fromActions.SetLogoLoadedAction(isLoading));
  }

  openFeedback() {
    this.store.dispatch(new fromActions.OpenFeedbackAction());
  }

  setFeedback(feedback: string) {
    this.store.dispatch(new fromActions.SetFeedbackAction(feedback));
  }
}
