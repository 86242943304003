import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  Directive,
  OnInit,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  HostListener
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { processFilesList } from '../../utils/helper';

@Directive({
  selector: '[marrickDropZone]'
})
export class DropZoneDirective implements OnInit {
  @Input() allowedFileTypes: Array<string> = [];
  @Input() allowMultipleFiles = false;
  @Input() dragEnterColor = '#dbdbdb';
  @Input() dragLeaveColor = '#f5f5f5';
  @Input() dragEnterTextColor = '#ffffff';
  @Input() dragLeaveTextColor = '#808080';
  @Input() dropZoneTextSize = '100%';
  @Output() validFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() invalidFiles: EventEmitter<File[]> = new EventEmitter();
  @Output() dragEnterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dragExitEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @HostBinding('style') style: SafeStyle;

  // Track the uppercase of the allowed file types.
  allowedFiles: Array<string> = [];

  constructor(private sanitizer: DomSanitizer, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.dragLeave();
    // Uppercase all allowed file types for matching.
    for (let i = 0; i < this.allowedFileTypes.length; i++) {
      this.allowedFiles.push(this.allowedFileTypes[i].toLocaleUpperCase());
    }
  }

  // Use dragover for both dragenter and dragover
  // to prevent the default browser actions and propagation.
  //
  // Change the background color of the container to
  // to indicate a droppable location.
  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragEnter();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragLeave();
  }

  @HostListener('mouseover')
  public onHover() {
    this.dragEnter();
  }

  @HostListener('mouseleave')
  public onMouseLeave() {
    this.dragLeave();
  }

  // Iterate over the files dropped looking
  // for valid and invalid extensions.
  // Read valid files and return both valid files
  // and invalid file names.
  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragLeave();

    const validFiles: File[] = [];
    const invalidFiles: File[] = [];

    // Get the files from the dataTransfer object
    const files = evt.dataTransfer.files;

    // Check if multiple files are dropped and are allowed
    if (files.length === 1 || (files.length > 1 && this.allowMultipleFiles)) {
      processFilesList(files, this.allowedFiles, validFiles, invalidFiles);
      this.validFiles.emit(validFiles);
      this.invalidFiles.emit(invalidFiles);
    } else {
      this.snackBar.open(`Please choose one file to upload.`, null, {
        duration: 3000,
        panelClass: 'error-text'
      });
    }
  }

  private dragEnter() {
    this.style = this.sanitizer.bypassSecurityTrustStyle(
      `
      background-color: ${this.dragEnterColor};
      font-size: ${this.dropZoneTextSize};
      color: ${this.dragEnterTextColor}
      `
    );
    this.dragEnterEvent.emit(true);
  }

  private dragLeave() {
    this.style = this.sanitizer.bypassSecurityTrustStyle(
      `
      background-color: ${this.dragLeaveColor};
      font-size: ${this.dropZoneTextSize};
      color: ${this.dragLeaveTextColor}
      `
    );
    this.dragExitEvent.emit(true);
  }
}
