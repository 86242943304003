import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({ providedIn: 'root' })
export class CanDeactivateGuardService
  implements CanDeactivate<CanComponentDeactivate> {
  /**
   * If the Component implements the canDeactivate function and
   * the route implments this CanComponentDeactivate  Service
   * the canDeactivate function on the Component will execute.
   */
  canDeactivate(component: CanComponentDeactivate) {
    if (component !== null) {
      return component.canDeactivate ? component.canDeactivate() : true;
    } else {
      return true;
    }
  }
}
